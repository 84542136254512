export const styles = theme => ({
  wrap: {
    width: 300,
  },
  button: {
    backgroundColor: theme.tabs.support.background,
    borderTop: '1px solid',
    borderColorTop: theme.tabs.support.border,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.tabs.support.buttonsText,
    fontWeight: 'bold',
  },
  lastButton: {
    backgroundColor: theme.tabs.support.background,
    borderTop: '1px solid',
    borderColorTop: theme.tabs.support.border,
    height: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.tabs.support.buttonsText,
    fontWeight: 'bold',
    borderRadius: '0px 0px 8px 8px',
  },
})
