import React, { FC, ChangeEvent, useState } from 'react'

import * as S from './PromptForm.style'
import { getDefaultCustomPrompt } from 'tabs/nlp/api/websiteKnowledgeBase'
import Input from 'uiKit/inputs/Input'
import { FIORD_BLUE } from 'constants/colors'
import { NewDeleteIcon } from 'uiKit/icons/NewDeleteIcon'

interface PromptFormProps {
  setEnableSaveConfig: (value: boolean) => void
  updatePrompt: (prompt: string, name: string) => void
  updatePromptName: (name: string, newName: string) => void
  updatePromptPhrase: (name: string, phrase: string) => void
  removePrompt: (name: string) => void
  savePrompts: (name: string) => void
  enableSaveConfig: boolean
  promptName: string
  promptText: string
  promptPhrase: string
}

const getRandomObject = (array: string[]) => {
  return array[Math.floor(Math.random() * array.length)]
}

const placeholderOptions = [
  'You are a smart AI assistant who ...',
  'You are a caribbean pirate who was never caught ...',
  'You are a librarian that loves recommending books ...',
  'You are a wizard that predicts future based on your knowledge ...',
]

export const PromptForm: FC<PromptFormProps> = ({
  updatePrompt,
  setEnableSaveConfig,
  promptName,
  promptText,
  updatePromptName,
  updatePromptPhrase,
  removePrompt,
  savePrompts,
  enableSaveConfig,
  promptPhrase,
}) => {
  const idDefaultPrompt = promptName === 'default'
  const [defaultPrompt] = useState(promptText)
  const [promptError, setError] = useState('')
  const [enableResetDefault, setEnableResetDefault] = useState(false)
  const [displayResetNote, setDisplayResetNote] = useState(false)

  const [placeholderText, setPlaceholderText] = useState('')

  const regeneratePlaceholder = () => {
    setPlaceholderText(getRandomObject(placeholderOptions))
  }

  const handleResetToDefault = () => {
    getDefaultCustomPrompt().then(result => updatePrompt(result, promptName))
    setDisplayResetNote(true)
    setError('')
    setEnableResetDefault(false)
    setEnableSaveConfig(true)
  }

  const maxPromptLength = 5000

  const handleChange = async ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    setEnableSaveConfig(true)
    const isInvalidLength = value.length > maxPromptLength
    const isZeroLength = value.length == 0
    const containsAnyLetter = /\p{L}/u.test(value)

    if (isInvalidLength) {
      setError(`You've reached the max length for the custom prompt — ${maxPromptLength} characters`)
      updatePrompt(value.substring(0, maxPromptLength), promptName)
      return
    } else {
      setError('')
    }
    updatePrompt(value, promptName)
    setEnableResetDefault(value !== defaultPrompt)
    setEnableSaveConfig(value !== defaultPrompt)

    isZeroLength && regeneratePlaceholder()

    if (!containsAnyLetter) {
      setEnableSaveConfig(false)
    }
  }

  const onInputPromptNameChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setEnableSaveConfig(true)
    updatePromptName(promptName, value)
  }

  const onInputPromptPhraseChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setEnableSaveConfig(true)
    updatePromptPhrase(promptName, value)
  }

  const removePromptClick = () => {
    setEnableSaveConfig(true)
    removePrompt(promptName)
  }

  return (
    <S.Container>
      <Input
        title="Prompt name"
        placeholder="Prompt name"
        value={promptName}
        onChange={onInputPromptNameChange}
        inputTitleStyles={{ margin: 0, color: `${FIORD_BLUE}`, fontSize: '14px', fontWeight: 700 }}
        style={{ marginBottom: '20px', width: '30%' }}
        disabled={idDefaultPrompt}
      />
      <Input
        title="Trigger phrase to switch to this prompt"
        placeholder="Trigger phrase"
        value={promptPhrase}
        onChange={onInputPromptPhraseChange}
        inputTitleStyles={{ margin: 0, color: `${FIORD_BLUE}`, fontSize: '14px', fontWeight: 700 }}
        style={{ marginBottom: '20px', width: '30%' }}
      />
      <S.Title>Customize prompt</S.Title>
      <S.Form>
        <S.Textarea placeholder={placeholderText} value={promptText} onChange={handleChange} isError={promptError} />
        <S.Error>{promptError}</S.Error>
        <S.ButtonArea>
          <S.SecondaryButton onClick={handleResetToDefault} disabled={!enableResetDefault}>
            Reset to default
          </S.SecondaryButton>
          <S.Button onClick={savePrompts} disabled={!enableSaveConfig}>
            Save changes
          </S.Button>
        </S.ButtonArea>
        {displayResetNote && (
          <S.SubHeader>
            If you intend to restore the prompt to its original settings, please click on the "Save changes" button.
          </S.SubHeader>
        )}
      </S.Form>
      {!idDefaultPrompt && (
        <S.DeleteWrapper onClick={removePromptClick}>
          <NewDeleteIcon />
        </S.DeleteWrapper>
      )}
    </S.Container>
  )
}
