import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import Heading from 'uiKit/texts/Heading'
import { KnowledgeBaseSubTabMap } from 'tabs/nlp/constants'

import RadioButtonGroup from './RadioButtonGroup'
import WebsiteTab from './WebsiteTab'
import TextTab from './TextTab'
import FileTab from './FileTab'
import SettingsTab from './SettingsTab'

import * as S from './KnowledgeBase.style'

const KnowledgeBase = props => {
  const {
    match: { params },
  } = props

  const subTabFromPath = params.sourceType
  const sourceId = params.sourceId
  const validatedSubTab = subTabFromPath in KnowledgeBaseSubTabMap && subTabFromPath
  const [activeTab, setActiveTab] = useState(validatedSubTab || KnowledgeBaseSubTabMap.SETTINGS)

  return (
    <S.Container>
      <Heading>AI Knowledge</Heading>
      <S.SubHeader>Connect different data sources for the chatbot knowledgebase</S.SubHeader>
      <RadioButtonGroup value={activeTab} onChange={setActiveTab} />
      {activeTab === KnowledgeBaseSubTabMap.WEBSITE && <WebsiteTab />}
      {activeTab === KnowledgeBaseSubTabMap.FILE && <FileTab />}
      {activeTab === KnowledgeBaseSubTabMap.TEXT && <TextTab sourceId={sourceId} />}
      {activeTab === KnowledgeBaseSubTabMap.SETTINGS && <SettingsTab />}
    </S.Container>
  )
}

export default withRouter(KnowledgeBase)
