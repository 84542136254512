import { theme } from 'constants/theme'
import styled from 'styled-components'

const SelectorContainer = styled.div`
  max-width: 400px;
  margin-bottom: 24px;
  position: relative;
  z-index: 3;

  & > div:first-child {
    margin-bottom: 8px;
  }
`

const SubHeader = styled.div`
  font-size: 12px;
  color: ${theme.tabs.nlp.subHeaderText};
`

const Button = styled.button`
  background: ${theme.tabs.nlp.menuButtonBackground};
  margin: 0 20px 20px 0;
  width: 128px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: ${theme.tabs.nlp.menuButtonText};
  font-size: 16px;
  &:disabled {
    background: ${theme.tabs.nlp.menuButtonBackgroundHover};
  }
`

export { SelectorContainer, SubHeader, Button }
