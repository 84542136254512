import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { alertSuccess } from 'api'
import {
  getNLPConfig,
  getAvailableGPTModels,
  saveNLPConfig,
  getDefaultCustomPrompt,
  savePromptPhrases,
  getPromptPhrases,
} from 'tabs/nlp/api/websiteKnowledgeBase'

import PromptForm from '../PromptForm'

import * as S from './SettingsTab.style'
import { KnowledgeBasePrompt, PromptPhrase } from 'models/KnowledgeBaseType'

type GPTModelOption = { value: string; label: string } | undefined

export const SettingsTab = ({ activeBot }): JSX.Element => {
  const [enableSaveConfig, setEnableSaveConfig] = useState(false)
  const [GPTModel, setGPTModel] = useState<GPTModelOption>()
  const [originalGPTModel, setOriginalGPTModel] = useState<GPTModelOption>()
  const [modelOptions, setModelOptions] = useState([])

  const [listPrompts, setListPrompts] = useState<KnowledgeBasePrompt[]>(null)

  const updatePrompt = (prompt: string, name: string) => {
    setListPrompts(listPrompts.map(item => (item.name === name ? { ...item, prompt } : item)))
  }

  const updatePromptName = (name: string, newName: string) => {
    setListPrompts(listPrompts.map(item => (item.name === name ? { ...item, name: newName } : item)))
  }

  const updatePromptPhrase = (name: string, phrase: string) => {
    setListPrompts(listPrompts.map(item => (item.name === name ? { ...item, phrase: phrase } : item)))
  }

  const removePrompt = (name: string) => {
    setListPrompts(listPrompts.filter(item => item.name !== name))
  }

  const createPrompt = () => {
    getDefaultCustomPrompt().then(result => {
      setListPrompts([{ prompt: result, name: `Prompt ${listPrompts.length + 1}` }, ...listPrompts])
      setEnableSaveConfig(true)
    })
  }

  const savePrompts = (prompts: KnowledgeBasePrompt[], phrases: PromptPhrase[]) => {
    const promptsWithPhrases = prompts.map(prompt => {
      return { ...prompt, phrase: phrases.find(phrase => phrase.promptName === prompt.name)?.phrase }
    })
    setListPrompts(promptsWithPhrases || [])
  }

  useEffect(() => {
    Promise.all([getAvailableGPTModels(), getNLPConfig(activeBot.id), getPromptPhrases(activeBot.id)]).then(
      ([GPTModels, nlpConfig, promptPhrases]) => {
        const optimizedOptions = GPTModels?.map(modelName => ({ label: modelName, value: modelName }))
        const selectedOption = optimizedOptions.find(model => model.value === nlpConfig.model)
        setModelOptions(optimizedOptions)
        setGPTModel(selectedOption)
        setOriginalGPTModel(selectedOption)
        savePrompts(nlpConfig.prompts || [], promptPhrases || [])
      },
    )
  }, [])

  const handleSave = () => {
    const requestPayload = {
      prompts: listPrompts.map(item => ({ name: item.name, prompt: item.prompt })),
      model: GPTModel.value,
    }

    Promise.all([
      saveNLPConfig(activeBot.id, requestPayload),
      savePromptPhrases(
        activeBot.id,
        listPrompts.map(item => ({ name: item.name, phrase: item.phrase })),
      ),
    ]).then(() => {
      alertSuccess('AI Knowledge settings are updated successfully.')
    })
    setOriginalGPTModel(GPTModel)
    setEnableSaveConfig(false)
  }

  const handleChangeModel = (e: GPTModelOption) => {
    setGPTModel(e)
    setEnableSaveConfig(e.value !== originalGPTModel.value)
  }

  return (
    <div>
      <S.SelectorContainer>
        <S.SubHeader>Model</S.SubHeader>
        <Select
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          options={modelOptions}
          simpleValue
          value={GPTModel}
          onChange={handleChangeModel}
          isLoading={!GPTModel}
          placeholder={false}
          isDisabled={!GPTModel}
        />
      </S.SelectorContainer>
      <S.Button onClick={createPrompt}>Add prompt</S.Button>
      <S.SubHeader>
        Custom prompt can be used to configure chatbot's features like personality, size of response, or just about
        anything.
      </S.SubHeader>
      {listPrompts &&
        listPrompts.map((item, ind) => (
          <PromptForm
            key={ind}
            promptName={item.name}
            promptText={item.prompt}
            promptPhrase={item.phrase}
            setEnableSaveConfig={setEnableSaveConfig}
            enableSaveConfig={enableSaveConfig}
            updatePrompt={updatePrompt}
            updatePromptName={updatePromptName}
            updatePromptPhrase={updatePromptPhrase}
            removePrompt={removePrompt}
            savePrompts={handleSave}
          />
        ))}
    </div>
  )
}
