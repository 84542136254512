import styled from 'styled-components'
import { theme } from 'constants/theme'
import { CADET_BLUE, COMET_GREY, FIORD_BLUE, PERIWINKLE, PERMISSION_RED, SELAGO_GRAY, WHITE } from 'constants/colors'

const Container = styled.div`
  width: 100%;
  background-color: ${WHITE};
  padding: 24px;
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
  margin-top: 10px;
  margin-bottom: 16px;
  position: relative;
`

const Title = styled.p`
  margin: 0;
  color: ${FIORD_BLUE};
  font-size: 14px;
  font-weight: 700;
`

const Form = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
  gap: 16px;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
`

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const SecondaryButton = styled.button`
  font-size: 14px;
  padding: 8px 16px;
  border: none;
  color: rgba(22, 88, 243, 1);
  width: fit-content;
  font-weight: 500;
  background-color: ${WHITE};
  border-radius: 10px;
  &:disabled {
    color: ${CADET_BLUE};
    &:hover {
      background-color: ${WHITE};
    }
  }
  &:hover {
    background-color: ${PERIWINKLE};
  }
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.3;
  background-color: ${SELAGO_GRAY};
`

const Textarea = styled.textarea<{ isError: boolean }>`
  position: relative;
  height: 176px;
  font-size: 14px;
  border-color: ${({ isError }) => isError && `${PERMISSION_RED}`};
`

const Error = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${PERMISSION_RED};
  display: flex;
  justify-content: flex-start;
  align-content: center;
`
const SubHeader = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${COMET_GREY};
  margin-top: -10px;
  height: 5px;
  display: flex;
  justify-content: flex-start;
`

const DeleteWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
`

const Button = styled.button`
  background: ${theme.tabs.nlp.menuButtonBackground};
  width: 128px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: ${theme.tabs.nlp.menuButtonText};
  font-size: 16px;
  &:disabled {
    background: ${theme.tabs.nlp.menuButtonBackgroundHover};
  }
`

export {
  Container,
  Title,
  Form,
  DisableScreen,
  SecondaryButton,
  Textarea,
  Error,
  ButtonArea,
  SubHeader,
  DeleteWrapper,
  Button,
}
